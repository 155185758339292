/* Vendor imports */
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
/* App imports */
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Utils from '../../utils'
import {container} from './index.module.less'
import {photo} from './index.module.less'
import {content} from './index.module.less'
import {iconWrapper} from './index.module.less'
import {iconsContainer} from './index.module.less'

export const aboutPropTypes = {
  data: PropTypes.shape({
    profilePhoto: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired,
    flagIt: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fixed: PropTypes.object.isRequired,
      }),
    }),
  }),
}

class About extends React.Component {
  static propTypes = aboutPropTypes

  render() {
    let { profilePhoto, flagIt} = this.props.data
    return (
      <Layout>
        <SEO
          title="About"
          description="当ブログ「駒と日常と私」の筆者についての紹介ページです。"
          path="about"
        />
        <div className={container}>
          <div className={photo}>
            <Img fluid={profilePhoto.childImageSharp.fluid} />
          </div>
          <div className={content}>
            <h1>管理人について</h1>
            <h2>「駒と日常と私」の管理人の零です。</h2>
            <p></p>
            <Img
                fixed={flagIt.childImageSharp.fixed}
                style={{ display: 'block', margin: 'auto' }}
              />
            <p>
            IT業界で従事する将棋好きのサラリーマンです。
            将棋はアマチュア参段。普段は将棋ウォーズや将棋クエストに潜ってのんびり対局を楽しんでいます。
            当ブログでは将棋に関する記事を中心に、日々のお役立ち情報や仕事関連についても時々触れていこうと思います。
            </p>
            <br />
          </div>
        </div>
      </Layout>
    )
  }
}

/**
 * <h2>Skills</h2>
   <ImageList edges={skillIcons.edges} />
   <h2>Tools</h2>
   <ImageList edges={toolIcons.edges} />
 * 
 */

export const imageListPropTypes = {
  edges: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        name: PropTypes.string.isRequired,
        childImageSharp: PropTypes.shape({
          fixed: PropTypes.object.isRequired,
        }).isRequired,
      }).isRequired,
    })
  ).isRequired,
}

class ImageList extends React.Component {
  static propTypes = imageListPropTypes

  render = () => (
    <div className={iconsContainer}>
      {this.props.edges
        .sort((edgeA, edgeB) =>
          edgeA.node.name.toLowerCase() > edgeB.node.name.toLowerCase() ? 1 : -1
        )
        .map(({ node: { name, childImageSharp } }) => (
          <div className={iconWrapper} key={name}>
            <Img
              fixed={childImageSharp.fixed}
              alt={name + ' logo'}
              title={name}
            />
            <label>
              {iconsNameMap[name] ? iconsNameMap[name] : Utils.capitalize(name)}
            </label>
          </div>
        ))}
    </div>
  )
}

export const query = graphql`
  {
    profilePhoto: file(name: { eq: "profile-photo" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    flagIt: file(name: { eq: "shogi" }) {
      childImageSharp {
        fixed(width: 50) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
  }
`
// Use to set specific icons names
export const iconsNameMap = {
  css: 'CSS',
  html: 'HTML',
  jquery: 'JQuery',
  nodejs: 'Node.js',
  vuejs: 'Vue.js',
  gruntjs: 'Grunt.js',
}

export default About
